import React from 'react';
import Card from '../Components/Card';

export default function Success() {
    return (
        <div className="Loginscreen bg-fuchsia-50 min-h-screen min-w-screen flex items-center justify-center">
            <Card>
                <div className="flex flex-col items-center justify-center space-y-4">
                    <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M86.2755 29.8694L38.7755 77.3694C38.3618 77.7846 37.8702 78.114 37.329 78.3387C36.7877 78.5635 36.2073 78.6792 35.6212 78.6792C35.0352 78.6792 34.4548 78.5635 33.9135 78.3387C33.3723 78.114 32.8807 77.7846 32.4669 77.3694L11.6857 56.5882C11.2715 56.174 10.9429 55.6822 10.7187 55.141C10.4945 54.5998 10.3792 54.0197 10.3792 53.4339C10.3792 52.8481 10.4945 52.268 10.7187 51.7268C10.9429 51.1856 11.2715 50.6938 11.6857 50.2796C12.0999 49.8654 12.5917 49.5368 13.1329 49.3126C13.6741 49.0884 14.2542 48.973 14.84 48.973C15.4258 48.973 16.0059 49.0884 16.5471 49.3126C17.0883 49.5368 17.5801 49.8654 17.9943 50.2796L35.625 67.9103L79.9744 23.5683C80.8109 22.7317 81.9456 22.2617 83.1287 22.2617C84.3118 22.2617 85.4464 22.7317 86.283 23.5683C87.1195 24.4048 87.5895 25.5395 87.5895 26.7226C87.5895 27.9057 87.1195 29.0403 86.283 29.8769L86.2755 29.8694Z" fill="#C01828"/>
                    </svg>

                    <h1 className="text-2xl text-center">Proceso Creado Satisfactoriamente</h1>

                    <p className="text-center">Hemos enviado un correo con la información a john_doe@mail.com</p>

                    <button
                        type="submit"
                        className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                    >
                        Volver a los Procesos
                    </button>
                </div>
            </Card>
        </div>
    );
}
