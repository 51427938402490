import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const PieChart = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [series, setSeries] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState({
    NP: true,
    PC: true,
    AE: true,
    MD: true,
    EP: true,
  });
  const [timeFrame, setTimeFrame] = useState('semana'); // Default time frame

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.mutualsalud.com/process/status/${timeFrame}/`);
        const statusData = response.data.statuses.reduce((acc, item) => {
          acc[item.status] = item.count;
          return acc;
        }, {});
        setSeries([
          statusData.NP || 0,
          statusData.PC || 0,
          statusData.AE || 0,
          statusData.MD || 0,
          statusData.EP || 0,
        ]);
      } catch (error) {
        console.error('Error fetching status data:', error);
      }
    };

    fetchData();
  }, [timeFrame]);

  const downloadCSV = async () => {
    try {
      const response = await axios.get(`https://api.mutualsalud.com/process/csv_file/${timeFrame}/`, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `process_stats_${timeFrame}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the CSV file:', error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getChartOptions = () => {
    return {
      series: series,
      colors: ["#1C64F2", "#16BDCA", "#FDBA8C", "#E74694", "#F00"],
      chart: {
        height: 320,
        width: "100%",
        type: "donut",
      },
      stroke: {
        colors: ["transparent"],
        lineCap: "",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: 20,
              },
              total: {
                showAlways: true,
                show: true,
                label: "Procesos totales",
                fontFamily: "Inter, sans-serif",
                formatter: function (w) {
                  const sum = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                  return sum;
                },
              },
              value: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: -20,
                formatter: function (value) {
                  return value;
                },
              },
            },
            size: "80%",
          },
        },
      },
      grid: {
        padding: {
          top: -2,
        },
      },
      labels: [
        "Sin Solicitud Abierta",
        "Solicitud Resuelta",
        "Esperando Verificación Entidad",
        "Documento Requerido",
        "Solicitud No Resuelta",
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontFamily: "Inter, sans-serif",
      },
    };
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedStatuses = { ...selectedStatuses, [name]: checked };
    setSelectedStatuses(updatedStatuses);

    const newSeries = [
      updatedStatuses.NP ? series[0] : 0,
      updatedStatuses.PC ? series[1] : 0,
      updatedStatuses.AE ? series[2] : 0,
      updatedStatuses.MD ? series[3] : 0,
      updatedStatuses.EP ? series[4] : 0,
    ];
    setSeries(newSeries);
  };

  const handleTimeFrameChange = (time) => {
    setTimeFrame(time);
    setDropdownOpen(false);
  };

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <div className="flex justify-between mb-3">
        <div className="flex justify-center items-center">
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white pe-1">Estado del Proceso</h5>
          <svg
            data-popover-target="chart-info"
            data-popover-placement="bottom"
            className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white cursor-pointer ms-1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z" />
          </svg>
          <div
            data-popover
            id="chart-info"
            role="tooltip"
            className="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400"
          >
            <div className="p-3 space-y-2">
              <h3 className="font-semibold text-gray-900 dark:text-white">Activity growth - Incremental</h3>
              <p>Report helps navigate cumulative growth of community activities. Ideally, the chart should have a growing trend, as stagnating chart signifies a significant decrease of community activity.</p>
              <h3 className="font-semibold text-gray-900 dark:text-white">Calculation</h3>
              <p>For each date bucket, the all-time volume of activities is calculated. This means that activities in period n contain all activities up to period n, plus the activities generated by your community in period.</p>
              <a
                href="#"
                className="flex items-center font-medium text-blue-600 dark:text-blue-500 dark:hover:text-blue-600 hover:text-blue-700 hover:underline"
              >
                Read more
                <svg className="w-2 h-2 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                </svg>
              </a>
            </div>
            <div data-popper-arrow></div>
          </div>
        </div>
        <div>
          <button
            type="button"
            data-tooltip-target="data-tooltip"
            data-tooltip-placement="bottom"
            className="hidden sm:inline-flex items-center justify-center text-gray-500 w-8 h-8 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm"
            onClick={downloadCSV} // Add the onClick event
          >
            <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
            </svg>
            <span className="sr-only">Download data</span>
          </button>
          <div
            id="data-tooltip"
            role="tooltip"
            className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
          >
            Download CSV
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-wrap" id="statuses">
          <div className="flex items-center me-4">
            <input
              id="NP"
              name="NP"
              type="checkbox"
              checked={selectedStatuses.NP}
              onChange={handleCheckboxChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="NP" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Sin Solicitud Abierta</label>
          </div>
          <div className="flex items-center me-4">
            <input
              id="PC"
              name="PC"
              type="checkbox"
              checked={selectedStatuses.PC}
              onChange={handleCheckboxChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="PC" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Solicitud Resuelta</label>
          </div>
          <div className="flex items-center me-4">
            <input
              id="AE"
              name="AE"
              type="checkbox"
              checked={selectedStatuses.AE}
              onChange={handleCheckboxChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="AE" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Esperando Verificación Entidad</label>
          </div>
          <div className="flex items-center me-4">
            <input
              id="MD"
              name="MD"
              type="checkbox"
              checked={selectedStatuses.MD}
              onChange={handleCheckboxChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="MD" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Documento Requerido</label>
          </div>
          <div className="flex items-center me-4">
            <input
              id="EP"
              name="EP"
              type="checkbox"
              checked={selectedStatuses.EP}
              onChange={handleCheckboxChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="EP" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Solicitud No Resuelta</label>
          </div>
        </div>
      </div>

      <div className="py-6">
        <ReactApexChart options={getChartOptions()} series={series} type="donut" height={320} />
      </div>

      <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
        <div className="flex justify-between items-center pt-5">
          <button
            id="dropdownDefaultButton"
            onClick={toggleDropdown}
            className="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
            type="button"
          >
            {`Ultimo ${timeFrame}`}
            <svg className="w-2.5 m-2.5 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
            </svg>
          </button>
          {dropdownOpen && (
            <div id="lastDaysdropdown" className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                <li>
                  <button onClick={() => handleTimeFrameChange('dia')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimos Dias</button>
                </li>
                <li>
                  <button onClick={() => handleTimeFrameChange('semana')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultima Semana</button>
                </li>
                <li>
                  <button onClick={() => handleTimeFrameChange('mes')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimo Mes</button>
                </li>
                <li>
                  <button onClick={() => handleTimeFrameChange('año')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimo Año</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PieChart;

