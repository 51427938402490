import React, { useState, useEffect } from 'react';
import Card from '../Components/Card';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function CreateProcess() {
    const userId = sessionStorage.getItem('userId');
    const navigate = useNavigate();
    const [formPage, setFormPage] = useState(1);
    const [typeDocument, setTypeDocument] = useState('');
    const [uniqueIdentifier, setUniqueIdentifier] = useState('');
    const [fullName, setFullName] = useState('');
    const [dOB, setDOB] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [typeRequirement, setTypeRequirement] = useState('');
    const [unidadNegocio, setUnidadNegocio] = useState('');
    const [entidad, setEntidad] = useState('');
    const [description, setDescription] = useState('');
    const [citySelected, setCitySelected] = useState('');
    const [cities, setCities] = useState([]);
    const [initialUserData, setInitialUserData] = useState(null);
    const [role, setRole] = useState('');
    const [filteredEntities, setFilteredEntities] = useState([]);

    const handleNext = () => {
        setFormPage(2);
    };

    const handleBack = () => {
        setFormPage(1);
    };

    const fetchCities = async () => {
        try {
            const response = await axios.get('https://api.mutualsalud.com/process/cities/?format=json');
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const roleFromStorage = sessionStorage.getItem('role');
            setRole(roleFromStorage);
            if (roleFromStorage === 'client') {
                try {
                    const response = await axios.get(`https://api.mutualsalud.com/users/${userId}/`);
                    const userData = response.data;
                    setTypeDocument(userData.type_document);
                    setUniqueIdentifier(userData.unique_identifier);
                    setFullName(userData.full_name);
                    setDOB(userData.date_given);
                    setEmail(userData.email);
                    setPhoneNumber(userData.phone_number);
                    setInitialUserData(userData);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        if (userId) {
            fetchUserData();
            fetchCities();
        }
    }, [userId]);

    /*
                                    <option value="">Seleccione una opción</option>
                                    <option value="1">Compensación AFP</option>
                                    <option value="2">Atención EPS</option>
                                    <option value="3">Compensación EPS</option>
                                    <option value="4">Incapacidad EPS</option>
                                    <option value="5">Incapacidad ARL</option>
                                    <option value="6">Traslado EPS</option>
                                    <option value="7">Reclamo medicamentos</option>

    */

    useEffect(() => {
        const entityMapping = {
            "1": ["PORVENIR", "PROTECCION", "COLPENSIONES", "COLFONDOS", "SKANDIA"], // Compensación AFP
            "2": ["Comfenalco Valle EPS", "Compensar EPS", "Coosalud EPS", "EPS Caprecom", "EPS Comparta", "EPS Convida", "EPS Coomeva", "EPS Coosalud", "EPS Ecoopsos", "EPS Emssanar", "EPS Famisanar", "EPS Mallamas", "EPS Mutual Ser", "EPS Pijaos Salud", "EPS Salud Vida", "EPS Sanitas", "EPS Savia Salud", "EPS Servicio Occidental de Salud SOS", "EPS Sura", "EPS Susalud", "EPS Tolima", "EPS Unimec", "EPS Valle", "Medimas EPS", "Nueva EPS", "Comfamiliar EPS", "Capital Salud EPS", "Aliansalud EPS"], // Atención EPS
            "4": ["Comfenalco Valle EPS", "Compensar EPS", "Coosalud EPS", "EPS Caprecom", "EPS Comparta", "EPS Convida", "EPS Coomeva", "EPS Coosalud", "EPS Ecoopsos", "EPS Emssanar", "EPS Famisanar", "EPS Mallamas", "EPS Mutual Ser", "EPS Pijaos Salud", "EPS Salud Vida", "EPS Sanitas", "EPS Savia Salud", "EPS Servicio Occidental de Salud SOS", "EPS Sura", "EPS Susalud", "EPS Tolima", "EPS Unimec", "EPS Valle", "Medimas EPS", "Nueva EPS", "Comfamiliar EPS", "Capital Salud EPS", "Aliansalud EPS"], // Incapacidad EPS
            "5": ["EQUIDAD"], // Incapacidad ARL
            "6": ["Comfenalco Valle EPS", "Compensar EPS", "Coosalud EPS", "EPS Caprecom", "EPS Comparta", "EPS Convida", "EPS Coomeva", "EPS Coosalud", "EPS Ecoopsos", "EPS Emssanar", "EPS Famisanar", "EPS Mallamas", "EPS Mutual Ser", "EPS Pijaos Salud", "EPS Salud Vida", "EPS Sanitas", "EPS Savia Salud", "EPS Servicio Occidental de Salud SOS", "EPS Sura", "EPS Susalud", "EPS Tolima", "EPS Unimec", "EPS Valle", "Medimas EPS", "Nueva EPS", "Comfamiliar EPS", "Capital Salud EPS", "Aliansalud EPS"], // Traslado EPS
            "7": ["Comfenalco Valle EPS", "Compensar EPS", "Coosalud EPS", "EPS Caprecom", "EPS Comparta", "EPS Convida", "EPS Coomeva", "EPS Coosalud", "EPS Ecoopsos", "EPS Emssanar", "EPS Famisanar", "EPS Mallamas", "EPS Mutual Ser", "EPS Pijaos Salud", "EPS Salud Vida", "EPS Sanitas", "EPS Savia Salud", "EPS Servicio Occidental de Salud SOS", "EPS Sura", "EPS Susalud", "EPS Tolima", "EPS Unimec", "EPS Valle", "Medimas EPS", "Nueva EPS", "Comfamiliar EPS", "Capital Salud EPS", "Aliansalud EPS"], // Reclamo medicamentos
            "8": ["Comfenalco Valle EPS", "Compensar EPS", "Coosalud EPS", "EPS Caprecom", "EPS Comparta", "EPS Convida", "EPS Coomeva", "EPS Coosalud", "EPS Ecoopsos", "EPS Emssanar", "EPS Famisanar", "EPS Mallamas", "EPS Mutual Ser", "EPS Pijaos Salud", "EPS Salud Vida", "EPS Sanitas", "EPS Savia Salud", "EPS Servicio Occidental de Salud SOS", "EPS Sura", "EPS Susalud", "EPS Tolima", "EPS Unimec", "EPS Valle", "Medimas EPS", "Nueva EPS", "Comfamiliar EPS", "Capital Salud EPS", "Aliansalud EPS"] // Reclamo medicamentos
        };

        if (typeRequirement) {
            setFilteredEntities(entityMapping[typeRequirement] || []);
        }
    }, [typeRequirement]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const processPayload = {
            type: typeRequirement,
            unity: unidadNegocio,
            entity: entidad,
            status: "NP",
            date_requested: new Date().toISOString(),
            last_update: new Date().toISOString(),
            description: description,
            country: 1,
            city: citySelected,
            owner: uniqueIdentifier,
        };

        const userPayload = {
            type_document: typeDocument,
            unique_identifier: uniqueIdentifier,
            full_name: fullName,
            date_given: dOB,
            email: email,
            phone_number: phoneNumber,
            role: 'client'
        };

        const isUserDataChanged = () => {
            if (!initialUserData) return false;
            return (
                initialUserData.type_document !== typeDocument ||
                initialUserData.unique_identifier !== uniqueIdentifier ||
                initialUserData.full_name !== fullName ||
                initialUserData.date_given !== dOB ||
                initialUserData.email !== email ||
                initialUserData.phone_number !== phoneNumber
            );
        };

        try {
            let userExists = false;
            try {
                const userResponse = await axios.get(`https://api.mutualsalud.com/users/${uniqueIdentifier}/`);
                if (userResponse.status === 200) {
                    userExists = true;
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    userExists = false;
                } else {
                    throw error;
                }
            }

            if (!userExists) {
                await axios.post('https://api.mutualsalud.com/users/', userPayload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            }

            const processResponse = await axios.post('https://api.mutualsalud.com/process/', processPayload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            navigate('/dashboard');
        } catch (error) {
            console.error('There was an error creating the process!', error);
        }
    };

    return (
        <div className="Loginscreen bg-fuchsia-50 min-h-screen min-w-screen flex items-center justify-center">
            <Card>
                <h1 className="text-3xl font-bold">Crear Nuevo Proceso</h1>
                <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                    {formPage === 1 && (
                        <>
                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Tipo de Documento:
                                </label>
                                <select
                                    name="typeDocument"
                                    value={typeDocument}
                                    onChange={(e) => setTypeDocument(e.target.value)}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value="CC">Cédula de Ciudadania </option>
                                    <option value="CE">Cedula de Extranjería</option>
                                    <option value="PEP">Permiso Especial de Permanencia</option>
                                </select>
                            </div>

                            {role === 'admin' && (
                                <div className="flex flex-col">
                                    <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                        Numero de Documento:
                                    </label>
                                    <input
                                        type="text"
                                        name="uniqueIdentifier"
                                        value={uniqueIdentifier}
                                        onChange={(e) => setUniqueIdentifier(e.target.value)}
                                        placeholder="Numero de Documento"
                                        className="mt-1 p-2 border border-gray-300 rounded"
                                    />
                                </div>
                            )}
                            {role === 'client' && (
                                <div className="flex flex-col">
                                    <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                        Numero de Documento:
                                    </label>
                                    <input
                                        type="text"
                                        readOnly
                                        disabled
                                        name="uniqueIdentifier"
                                        value={uniqueIdentifier}
                                        onChange={(e) => setUniqueIdentifier(e.target.value)}
                                        placeholder="Numero de Documento"
                                        className="mt-1 p-2 border border-gray-300 rounded"
                                    />
                                </div>
                            )}

                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Nombre Completo:
                                </label>
                                <input
                                    type="text"
                                    name="fullName"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    placeholder="John Doe"
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>

                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Correo Electronico:
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Correo Electronico"
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>

                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Numero de Celular:
                                </label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Numero de Celular"
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {role === 'admin' && (
                                <div className="flex flex-col">
                                    <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                        Fecha de Nacimiento:
                                    </label>
                                    <input
                                        type="date"
                                        name="dOB"
                                        value={dOB}
                                        onChange={(e) => setDOB(e.target.value)}
                                        className="mt-1 p-2 border border-gray-300 rounded"
                                    />
                                </div>
                            )}
                            {role === 'client' && (
                                <div className="flex flex-col">
                                    <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                        Fecha de Nacimiento:
                                    </label>
                                    <input
                                        type="date"
                                        name="dOB"
                                        value={dOB}
                                        readOnly
                                        disabled
                                        onChange={(e) => setDOB(e.target.value)}
                                        className="mt-1 p-2 border border-gray-300 rounded"
                                    />
                                </div>
                            )}

                            <button type="button" onClick={handleNext} className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
                                Next
                            </button>
                        </>
                    )}
                    {formPage === 2 && (
                        <>
                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Tipo de Requerimiento:
                                </label>
                                <select
                                    name="typeRequirement"
                                    value={typeRequirement}
                                    onChange={(e) => setTypeRequirement(e.target.value)}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value="1">Compensación AFP</option>
                                    <option value="2">Atención EPS</option>
                                    <option value="4">Incapacidad EPS</option>
                                    <option value="5">Incapacidad ARL</option>
                                    <option value="6">Traslado EPS</option>
                                    <option value="7">Reclamo medicamentos</option>
                                </select>
                            </div>

                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Unidad de Negocio:
                                </label>
                                <select
                                    name="unidadNegocio"
                                    value={unidadNegocio}
                                    onChange={(e) => setUnidadNegocio(e.target.value)}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value="DIBOG">DIBOG</option>
                                    <option value="DITBO">DITBO</option>
                                    <option value="DICAL">DICAL</option>
                                    <option value="DIPAS">DIPAS</option>
                                    <option value="ALKOSTO">ALKOSTO</option>
                                    <option value="REPME">REPME</option>
                                    <option value="DIMED">DIMED</option>
                                    <option value="DITME">DITME</option>
                                </select>
                            </div>

                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Ciudad de Radicación:
                                </label>
                                <select
                                    name="citySelected"
                                    value={citySelected}
                                    onChange={(e) => setCitySelected(e.target.value)}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                >
                                    <option value="">Seleccione una opción</option>
                                    {cities.map(city => (
                                        <option key={city.id} value={city.id}>{city.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Entidad donde se presenta la novedad:
                                </label>
                                <select
                                    name="entidad"
                                    value={entidad}
                                    onChange={(e) => setEntidad(e.target.value)}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                >
                                    <option value="">Seleccione una opción</option>
                                    {filteredEntities.map(entity => (
                                        <option key={entity} value={entity}>{entity}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex flex-col">
                                <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                    Observaciones:
                                </label>
                                <input
                                    type="text"
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Observaciones"
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <button type="button" onClick={handleBack} className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
                                Back
                            </button>
                            <button type="submit" className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
                                Submit
                            </button>
                        </>
                    )}
                </form>
            </Card>
        </div>
    );
}
