import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AttachmentItem from '../Components/AttachmentItem';

export default function Process() {
    //const userId = sessionStorage.getItem('userId');
    const [userId, setUserId] = useState(null);
    const { processId } = useParams();
    const navigate = useNavigate();
    const [process, setProcess] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [cityName, setCityName] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [role, setRole] = useState('');
    const [status, setStatus] = useState(null);
    const [comments, setComments] = useState(' ');


    const handleStatusChange = async (newStatus) => {
        try {
            const response = await axios.put(`https://api.mutualsalud.com/process/update_status/${processId}/`, { status: newStatus }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setStatus(newStatus);
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Error updating status');
        }
    };

    

    useEffect(() => {
        // Fetch process details from the API
        const roleFromStorage = sessionStorage.getItem('role');
        setRole(roleFromStorage);
        const fetchProcess = async () => {
            try {
                const response = await axios.get(`https://api.mutualsalud.com/process/${processId}/?format=json`);
                setProcess(response.data);
                setLoading(false);
                //console.log(response.data.owner);
                setUserId(response.data.owner); // Set userId from process owner
            } catch (error) {
                console.error("There was an error fetching the process data!", error);
                setLoading(false);
            }
        };

        fetchProcess();
    }, [processId]);

    useEffect(() => {
        if (process) {
            // Fetch city name using process.city
            const fetchCityName = async () => {
                try {
                    const response = await axios.get(`https://api.mutualsalud.com/process/cities/${process.city}/?format=json`);
                    setCityName(response.data.name); // Assuming the city data contains a 'name' field
                } catch (error) {
                    console.error('Error fetching city data:', error);
                }
            };

            fetchCityName();
        }
    }, [process]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://api.mutualsalud.com/users/${userId}/`);
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (userId) {
            fetchUserData(); // Fetch user data once userId is set
        }
    }, [userId]);

    const getStatusColor = (status) => {
        switch (status) {
            case "NP":
                return "var(--blue)";
            case "PC":
                return "var(--green)";
            case "AE":
                return "var(--orange)";
            case "MD":
                return "var(--yellow)";
            case "EP":
                return "var(--red)";
            default:
                return "var(--secondary)";
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };


    
    const handleFileUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('process_id', processId);
        formData.append('comments', comments);

        try {
            await axios.post(`https://api.mutualsalud.com/process/upload/${processId}/${role}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Documento cargado satisfactoriamente!');
        } catch (error) {
            console.error('Error uploading file:', error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Data:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
                alert(`Error al subir el archivo: ${error.response.data.error}`);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Request:', error.request);
                alert('Error al subir el archivo: No se recibió respuesta del servidor');
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
                alert(`Error al subir el archivo: ${error.message}`);
            }
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`https://api.mutualsalud.com/process/${processId}/`);
            alert('Proceso eliminado satisfactoriamente');
            navigate('/dashboard');
        } catch (error) {
            console.error('There was an error deleting the process!', error);
            alert('Error eliminando proceso');
        }
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!process) {
        return <div>Process not found</div>;
    }

    // Timeline Comments from process.comments (replace with real data if available)
      const timelineComments = process.comments || [
        {
            comment: "No hay comentarios",
            last_update: "2024-08-12T13:24:26.449000Z"
        }
    ];

    return (
        <div className="Loginscreen bg-fuchsia-50 min-h-screen min-w-screen flex items-center justify-center">
            <div className='flex flex-col w-2/4 h-auto'>
                <div 
                    className='HeaderPart rounded-t-lg flex flex-col p-8 items-center justify-center'
                    style={{ backgroundColor: getStatusColor(process.status) }}
                >

    <button onClick={handleDelete}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 32 32">
                            <path fill="#C01828" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m0 26C9.4 28 4 22.6 4 16S9.4 4 16 4s12 5.4 12 12s-5.4 12-12 12"/>
                            <path fill="#C01828" d="M21.4 23L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/>
                        </svg>
                    </button>


                    <h1 className='text-3xl font-bold'> {process.full_type} </h1>
                    <p> ID: {process.id} </p>
                    <p> Unidad de Negocio: {process.unity} </p>
                    {cityName && <p> Ciudad de Radicación: {cityName} </p>}
                    {user && <p> {user.email} </p>}
                    <p> Metodo de Radicacion: Plataforma </p>
                    <p> Fecha de creacion: {new Date(process.date_requested).toLocaleString(undefined, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    })} </p>
                </div>

                <div className='flex flex-row justify-between bg-white pl-2 pr-2'>
                    {user && <p> {user.type_document}: {user.unique_identifier} </p>}
                    {user && <p> {user.phone_number} </p>}
                    <p> Entidad: {process.entity} </p>
                </div>

                <div className='bg-white rounded-b-lg p-4 items-center flex flex-col justify-center'>

                    {role === 'admin' && (
                        <div>
                            <label htmlFor="status">Cambiar Estado:</label>
                            <select
                                id="status"
                                value={status}
                                onChange={(e) => handleStatusChange(e.target.value)}
                            >
                                <option value="NP">Sin Solicitud Abierta</option>
                                <option value="PC">Solicitud Resuelta</option>
                                <option value="AE">Esperando Verificación Entidad</option>
                                <option value="MD">Documento Requerido</option>
                                <option value="EP">Solicitud No Resuelta</option>
                            </select>
                        </div>
                    )}

                    <p className='font-semibold'>Observaciones</p>
                    <p>{process.description || "No hay observaciones"}</p>

                    <p className='font-semibold'>Adjuntos Usuario</p>
                    <div className="ListAttachmentUser flex flex-col gap-1">
                        {process.afiliado_docs && (
                            <AttachmentItem type_file="pdf" file_url={process.afiliado_docs} name_file={process.afiliado_docs.split('/').pop()} uploaded_file={new Date(process.date_requested).toLocaleString(undefined, {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit'
                            })} />
                        )}
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">
                        Cargar Adjunto
                        </label>
                        <input 
                        className="block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer" 
                        id="file_input" 
                        type="file"
                        onChange={handleFileChange}
                        />

                        {role === 'admin' &&  
                        
                        <div>
                                     <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                            Observaciones Archivo
                        </label>
                        <input type='text' id="observaciones_input" onChange={(event) => {setComments(event.target.value)}}/>
                        </div>
                            }

               
             

               
                      
                        <button
                            onClick={handleFileUpload}
                            className="bg-primary text-white font-bold p-2 px-4 rounded hover:bg-blue-700 mt-2"
                        >
                            Subir Archivo
                        </button>

                    </div>

                    <p className='font-semibold'>Documento Respuesta</p>
                    <div className="ListAttachmentUser flex flex-col gap-1">
                        {process.response_file && (
                        <AttachmentItem type_file="pdf" file_url={process.response_file} name_file={process.response_file.split('/').pop()} uploaded_file={new Date(process.date_requested).toLocaleString(undefined, {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit'
                            })} />
                        )}
                    </div>


                    {role !== 'admin' &&  
                        
                        <div>
                                     <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                            Observaciones Respuesta
                        </label>
                                 {/* Linea de Tiempo Comentarios */}
                    <h2 className="text-xl font-bold mb-4">Linea de Tiempo Comentarios</h2>
                    <ol className="relative border-s border-gray-200 dark:border-gray-700">
                        {timelineComments.map((comment, index) => (
                            <li key={index} className="mb-10 ms-4">
                                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                    {new Date(comment.last_update).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
                                </time>
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Comentario #{index + 1}</h3>
                                <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{comment.comment}</p>
                            </li>
                        ))}
                    </ol>
                        </div>
                            }

                    <button
                        onClick={() => navigate('/dashboard')}
                        className="bg-primary text-white font-bold p-2 px-4 rounded hover:bg-blue-700 mt-6"
                    >
                        Volver a mis Procesos
                    </button>
                </div>
            </div>

            {/* Add buttons to the lower right part of the screen */}
            <div className="fixed bottom-4 right-4 flex flex-col items-end space-y-2">
                <button 
                    className="bg-green-500 p-4 rounded-full shadow-lg"
                    onClick={() => navigate('/create')}
                >
                    <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 5a1 1 0 01.993.883L11 6v4h4a1 1 0 01.117 1.993L15 12h-4v4a1 1 0 01-1.993.117L9 16v-4H5a1 1 0 01-.117-1.993L5 10h4V6a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="bg-red-500 p-4 rounded-full shadow-lg">
                    <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-3-9a3 3 0 116 0 3 3 0 01-6 0z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
    );
}

