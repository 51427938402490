import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DashboardCard from '../Components/DashboardCard';
import ProcessItem from '../Components/ProcessItem';
import ProcessTitle from '../Components/ProcessTitle';
import TimeSeriesChart from '../Components/TimeSeriesChart';
import PieChart from '../Components/PieChart';
import BarChart from '../Components/BarChart';

const ITEMS_PER_PAGE = 5;

export default function Dashboard() {
    const navigate = useNavigate();
    const [processes, setProcesses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [role, setRole] = useState('');
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [afiliadoNumber, setAfiliadoNumber] = useState(''); // State for afiliado number

    useEffect(() => {
        // Retrieve user identifier and role from session storage
        const userId = sessionStorage.getItem('userId');
        const roleFromStorage = sessionStorage.getItem('role');
        setRole(roleFromStorage);

        if (!userId || !roleFromStorage) {
            // If no user data, redirect to login
            navigate('/');
            return;
        }

        fetchProcesses(roleFromStorage, userId);
    }, [navigate]);

    const fetchProcesses = (role, userId) => {
        if (role === 'admin') {
            // Admin-specific API call
            axios.get(`https://api.mutualsalud.com/process/`)
                .then(response => {
                    setProcesses(response.data);
                })
                .catch(error => {
                    console.error('Error fetching processes:', error);
                });
        } else {
            // Non-admin API call
            const birthDate = sessionStorage.getItem('birthDate');
            if (!birthDate) {
                navigate('/');
                return;
            }

            axios.get(`https://api.mutualsalud.com/users/${userId}/${birthDate}/`)
                .then(response => {
                    setProcesses(response.data.processes);
                })
                .catch(error => {
                    console.error('Error fetching processes:', error);
                });
        }
    };

    const sortProcesses = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);

        const sortedProcesses = [...processes].sort((a, b) => {
            if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
            if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
            return 0;
        });

        setProcesses(sortedProcesses);
    };

    const totalPages = Math.ceil(processes.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const selectedProcesses = processes.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAfiliadoLook = () => {
        if (!afiliadoNumber) {
            alert("Please enter an Afiliado number");
            return;
        }

        axios.get(`https://api.mutualsalud.com/users/get_process/${afiliadoNumber}/`)
            .then(response => {
                setProcesses(response.data.processes);
            })
            .catch(error => {
                console.error('Error fetching processes for afiliado:', error);
            });
    };

    const handleFetchAllProcesses = () => {
        const userId = sessionStorage.getItem('userId');
        const roleFromStorage = sessionStorage.getItem('role');
        fetchProcesses(roleFromStorage, userId);
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return date.toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <div className="Loginscreen bg-fuchsia-50 min-h-screen min-w-screen flex items-center justify-center">
            <DashboardCard>
                {role === 'admin' && (
                    <div>
                        {page === 1 &&
                            <div className="flex flex-row gap-2">
                                <button className="text-3xl font-bold" onClick={() => { setPage(1); handleFetchAllProcesses(); }}>
                                    Mis Procesos
                                </button>
                                <button className="text-3xl font-bold text-gray-600/50" onClick={() => { setPage(2); }}>
                                    Estadísticas
                                </button>

                                <div className="flex flex-row">
                                    <div className="flex flex-col">
                                        <label>Numero de Documento</label>
                                        <input type="number" value={afiliadoNumber} onChange={(e) => setAfiliadoNumber(e.target.value)} />
                                    </div>

                                    <button className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-blue-700" onClick={handleAfiliadoLook}>Buscar</button>
                                </div>
                            </div>
                        }

                        {page === 2 &&
                            <div className="flex flex-row gap-2">
                                <button className="text-3xl font-bold text-gray-600/50" onClick={() => { setPage(1); handleFetchAllProcesses(); }}>
                                    Mis Procesos
                                </button>
                                <button className="text-3xl font-bold" onClick={() => { setPage(2); }}>
                                    Estadísticas
                                </button>
                            </div>
                        }
                    </div>
                )}

                {role === 'client' && (
                    <div>
                        <div className="flex flex-row gap-2">
                            <button className="text-3xl font-bold" onClick={() => { setPage(1); handleFetchAllProcesses(); }}>
                                Mis Procesos
                            </button>
                        </div>
                    </div>
                )}

                {page === 1 && (
                    <div>
                        <ProcessTitle sortProcesses={sortProcesses} />

                        <div className='ProcessList flex flex-col gap-1'>
                            {selectedProcesses.map((process) => (
                                <ProcessItem
                                    key={process.id}
                                    processId={process.id}
                                    type_requirement={process.full_type}
                                    date_requested={formatDateTime(process.date_requested)}
                                    update_date={formatDateTime(process.last_update)}
                                    status={process.full_status}
                                    status_small={process.status}
                                />
                            ))}
                        </div>

                        <div className='flex flex-row items-center justify-center gap-2 pt-5 flex-wrap'>
                            {[...Array(totalPages).keys()].map(pageNumber => (
                                <button
                                    key={pageNumber + 1}
                                    onClick={() => handlePageClick(pageNumber + 1)}
                                    className={`px-3 py-1 rounded ${currentPage === pageNumber + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                                >
                                    {pageNumber + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                )}

                {page === 2 && (
                    <div>
                        <div className="flex flew-row items-center justify-center gap-2 pt-5">
                            <BarChart />
                            <PieChart />
                            <TimeSeriesChart />
                        </div>
                    </div>
                )}
            </DashboardCard>

            {/* Add buttons to the lower right part of the screen */}
            <div className="fixed bottom-4 right-4 flex flex-col items-end space-y-2">
                <button
                    className="bg-green-500 p-4 rounded-full shadow-lg"
                    onClick={() => navigate('/create')}
                >
                    <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 5a1 1 0 01.993.883L11 6v4h4a 1 1 0 01.117 1.993L15 12h-4v4a 1 1 0 01-1.993.117L9 16v-4H5a 1 1 0 01-.117-1.993L5 10h4V6a 1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="bg-red-500 p-4 rounded-full shadow-lg">
                    <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-3-9a3 3 0 116 0 3 3 0 01-6 0z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
    );
}
