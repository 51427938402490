import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const TimeSeriesChart = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [series, setSeries] = useState([{ name: 'New Processes', data: [] }]);
  const [categories, setCategories] = useState([]);
  const [timeFrame, setTimeFrame] = useState('semana');
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = async (timeFrame) => {
    try {
      const response = await axios.get(`https://api.mutualsalud.com/process/new_processes/${timeFrame}/`);
      const data = response.data.data;
  
      const newSeriesData = data.map(item => item.count);
      const newCategories = data.map(item => item.date);
      
      setSeries([{ name: 'New Processes', data: newSeriesData }]);
      setCategories(newCategories);
  
      // Calculate total count and set it
      const total = newSeriesData.reduce((acc, curr) => acc + curr, 0);
      setTotalCount(total);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(timeFrame);
  }, [timeFrame]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleTimeFrameChange = (time) => {
    setTimeFrame(time);
    setDropdownOpen(false);
  };

  const options = {
    chart: {
      height: "100%",
      maxWidth: "100%",
      type: "area",
      fontFamily: "Inter, sans-serif",
      dropShadow: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
        shade: "#1C64F2",
        gradientToColors: ["#1C64F2"],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 6,
    },
    grid: {
      show: false,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: 0
      },
    },
    series: series,
    xaxis: {
      categories: categories,
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
<div className="flex justify-between">
  <div>
    <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">{totalCount}</h5>
    <p className="text-base font-normal text-gray-500 dark:text-gray-400">Procesos Creados</p>
  </div>

</div>
      <ReactApexChart options={options} series={series} type="area" height="200" />
      <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
        <div className="flex justify-between items-center pt-5">
          <button
            id="dropdownDefaultButton"
            onClick={toggleDropdown}
            className="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
            type="button"
          >
            Ultimos {timeFrame}
            <svg className="w-2.5 m-2.5 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
            </svg>
          </button>
          {dropdownOpen && (
            <div id="lastDaysdropdown" className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                <li>
                  <button onClick={() => handleTimeFrameChange('dia')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ayer</button>
                </li>
                <li>
                  <button onClick={() => handleTimeFrameChange('semana')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimos 7 dias</button>
                </li>
                <li>
                  <button onClick={() => handleTimeFrameChange('mes')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimos 30 dias</button>
                </li>
                <li>
                  <button onClick={() => handleTimeFrameChange('año')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimo año</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeSeriesChart;
