// Card.js
import React from 'react';

export default function DashboardCard({ children }) {
    return (
        <div className="Card w-80 md:w-9/12  lg:w-9/12 h-auto bg-white rounded-3xl p-8 shadow">
            {children}
        </div>
    );
}
