// Card.js
import React from 'react';

export default function Card({ children }) {
    return (
        <div className="Card w-96 h-auto bg-white rounded-3xl p-8 shadow">
            {children}
        </div>
    );
}
