import React from 'react';

export default function AttachmentItem({ type_file, name_file, uploaded_file, file_url }) {
    return (
        <div className="flex flex-row">
            <div className="bg-red-400 flex items-center p-2 justify-center rounded-l-lg flex-shrink">
                <p>{type_file}</p>
            </div>
            <div className="bg-zinc-200 flex flex-row p-2 space-x-4 flex-grow rounded-r-lg">
                <div className="flex-grow">
                    <a href={file_url} download={name_file} className="text-blue-600 hover:underline">
                        {name_file}
                    </a>
                </div>
                <div className="flex-grow">
                    <p>{uploaded_file}</p>
                </div>
            </div>
        </div>
    );
}
