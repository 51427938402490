import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

const BarChart = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState('semana');

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const fetchTopCities = async (time) => {
    try {
      const response = await axios.get(`https://api.mutualsalud.com/process/top_cities/${time}/`);
      const citiesData = response.data;

      const resolvedData = citiesData.map(city => city.resolved_count);
      const unresolvedData = citiesData.map(city => city.unresolved_count);
      const newCategories = citiesData.map(city => city.city__name);

      setSeries([
        {
          name: "Procesos Resueltos",
          color: "#31C48D",
          data: resolvedData,
        },
        {
          name: "Procesos No Resueltos",
          color: "#F05252",
          data: unresolvedData,
        },
      ]);
      setCategories(newCategories);
    } catch (error) {
      console.error('Error fetching top cities:', error);
    }
  };

  useEffect(() => {
    fetchTopCities(selectedTimeframe);
  }, [selectedTimeframe]);

  const options = {
    series: series,
    chart: {
      type: 'bar',
      height: 400,
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '100%',
        borderRadius: 6,
      },
    },
    xaxis: {
      categories: categories,
      labels: { style: { fontFamily: 'Inter, sans-serif' } },
    },
    yaxis: { labels: { style: { fontFamily: 'Inter, sans-serif' } } },
    grid: {
      padding: { left: 2, right: 2, top: -20 },
    },
  };

  const handleTimeframeChange = (timeframe) => {
    setSelectedTimeframe(timeframe);
    setDropdownOpen(false);
  };

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <div className="py-6">
        <ReactApexChart options={options} series={series} type="bar" height={400} />
      </div>

      <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
        <div className="flex justify-between items-center pt-5">
          <button
            id="dropdownDefaultButton"
            onClick={toggleDropdown}
            className="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
            type="button"
          >
            {`Últimos ${selectedTimeframe}`}
            <svg className="w-2.5 m-2.5 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
            </svg>
          </button>
          {dropdownOpen && (
            <div id="lastDaysdropdown" className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                <li>
                  <button onClick={() => handleTimeframeChange('dia')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimos Dias</button>
                </li>
                <li>
                  <button onClick={() => handleTimeframeChange('semana')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultima Semana</button>
                </li>
                <li>
                  <button onClick={() => handleTimeframeChange('mes')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimo Mes</button>
                </li>
                <li>
                  <button onClick={() => handleTimeframeChange('año')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Ultimo Año</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BarChart;
