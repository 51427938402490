import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../Components/Card';
import logo from '../LogoMutSalud.png';
import axios from 'axios';

export default function Login() {
    const [userId, setUserId] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [displayPassword, setDisplayPassword] = useState(false);
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(displayPassword){
            // means that this is admin
            try {
                const userData = {
                    unique_identifier: userId,
                    password: password
                }
                const response = await axios.post(`https://api.mutualsalud.com/users/check_user/`, userData);
                // Store user identifier and birthdate in session storage
                sessionStorage.setItem('userId', userId);
                //sessionStorage.setItem('birthDate', birthDate);
                sessionStorage.setItem('role', 'admin');
                // Redirect to the dashboard
                navigate('/dashboard');
            } catch (error) {
                console.error('Login error:', error);
            }
        }else{
            // means this is just a regular client
            try {
                const response = await axios.get(`https://api.mutualsalud.com/users/${userId}/${birthDate}/`);
                // Store user identifier and birthdate in session storage
                sessionStorage.setItem('userId', userId);
                sessionStorage.setItem('birthDate', birthDate);
                sessionStorage.setItem('role', 'client');
                
                // Redirect to the dashboard
                navigate('/dashboard');
            } catch (error) {
                console.error('Login error:', error);
            }
        }


    
    };

    return (
        <div className="Loginscreen bg-fuchsia-50 min-h-screen min-w-screen flex items-center justify-center">
            <Card>
                <div className="flex flex-col items-center justify-center w-full">
                    <img className="w-28" src={logo} alt="Logo" />
                    <form className="flex flex-col space-y-4 w-full" onSubmit={handleSubmit}>
                        <div className="flex flex-col">
                            <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                Numero de Identificacion:
                            </label>
                            <input
                                type="text"
                                name="numeroDeIdentificacion"
                                value={userId}
                                onChange={(e) => {setUserId(e.target.value); if( isNaN(e.target.value)) { setDisplayPassword(true); }else{setDisplayPassword(false); }}}
                                placeholder="Numero de Identificacion"
                                className="mt-1 p-2 border border-gray-300 rounded"
                            />
                        </div>
                        {displayPassword && <div className="flex flex-col">  
                            <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                Contraseña:
                            </label>
                            <input
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="mt-1 p-2 border border-gray-300 rounded"
                            /> </div>}
                        {!displayPassword && <div className="flex flex-col">
                            <label className="text-black text-sm font-semibold leading-tight tracking-tight">
                                Fecha de Nacimiento:
                            </label>
                            <input
                                type="date"
                                name="fechaDeNacimiento"
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                                className="mt-1 p-2 border border-gray-300 rounded"
                            />
                        </div>}
                        
                        <button
                            type="submit"
                            className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                        >
                            Buscar
                        </button>
                    </form>
                </div>
            </Card>
            <div className="fixed bottom-4 right-4 flex flex-col items-end space-y-2">
                <button className="bg-red-500 p-4 rounded-full shadow-lg">
                    <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-3-9a3 3 0 116 0 3 3 0 01-6 0z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
    );
}
