import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ProcessItem({ type_requirement, date_requested, update_date, status, status_small, processId }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/process/${processId}`);
    };

    const getStatusColor = (status_small) => {
        switch (status_small) {
            case "NP":
                return "var(--blue)";
            case "PC":
                return "var(--green)";
            case "AE":
                return "var(--orange)";
            case "MD":
                return "var(--yellow)";
            case "EP":
                return "var(--red)";
            default:
                return "var(--secondary)";
        }
    };
    
    return (
        <div onClick={handleClick} className="flex flex-row cursor-pointer">
            <div className="bg-secondary flex flex-row p-4 space-x-4 flex-grow rounded-l-lg">
                <div className="flex-grow">
                    <p>{type_requirement}</p>
                </div>
                <div className="flex-grow hidden md:block lg:block">
                    <p>{date_requested}</p>
                </div>
                <div className="flex-grow hidden md:block lg:block">
                    <p>{update_date}</p>
                </div>
            </div>
            <div className="flex items-center justify-center rounded-r-lg flex-shrink w-32" style={{ backgroundColor: getStatusColor(status_small) }}>
                <p>{status}</p>
            </div>
        </div>
    );
}
