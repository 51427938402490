import React from 'react';

export default function ProcessTitle({ sortProcesses }) {
    return (
        <div className="flex flex-row font-semibold">
            <div className="flex flex-row p-4 space-x-4 flex-grow rounded-l-lg">
                <div className="flex-grow">
                    <button onClick={() => sortProcesses('full_type')} className="font-bold">
                        Tipo de Requerimiento
                    </button>
                </div>
                <div className="flex-grow hidden md:block lg:block">
                    <button onClick={() => sortProcesses('date_requested')} className="font-bold">
                        Fecha de Solicitud
                    </button>
                </div>
                <div className="flex-grow hidden md:block lg:block">
                    <button onClick={() => sortProcesses('last_update')} className="font-bold">
                        Fecha de Actualización
                    </button>
                </div>
                <div className="flex-grow">
                    <button onClick={() => sortProcesses('full_status')} className="font-bold">
                        Estado
                    </button>
                </div>
            </div>
        </div>
    );
}
